<template>
    <div>
        <main class="main-content">
            <!--== Start Page Header Area Wrapper ==
            <div class="page-header-area sec-overlay sec-overlay-black" style="background-image:url(assets/img/slider/banner1101.png);background-position:100%;background-size:100%">
            <div class="container pt--0 pb--0">
                <div class="row">
                <div class="col-12">
                    <div class="page-header-content">
                    <h2 class="title">Word专区</h2>
                    <nav class="breadcrumb-area">
                        <ul class="breadcrumb justify-content-center">
                        <li><a href="index.html">Home</a></li>
                        <li class="breadcrumb-sep">//</li>
                        <li>Blog Post</li>
                        </ul>
                    </nav>
                    </div>
                </div>
                </div>
            </div>
            </div>
            == End Page Header Area Wrapper ==-->

            <!--== Start Blog Area Wrapper ==-->
            <section class="blog-area blog-grid-area">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                    <div class="job-details-wrap">
                    <div class="job-details-info">
                        <div class="thumb">
                        <img src="assets/img/photos/gjc.png" width="130" height="130" alt="Image-HasTech">
                        </div>
                        <div class="content">
                        <h1 class="title">关键词名称：{{this.key}}</h1>
                        <h5 class="sub-title">海量模板免费下载(仅供学习使用).</h5>

                        </div>
                    </div>
                    <div class="job-details-price">
                        <p>专区内容数量：<span>{{ModsLength}}</span></p>
                        <button type="button" class="btn-theme" @click="goModHome()">退出专区</button>
                    </div>
                    </div>
                </div>
                <div v-if="ModsLength==0">                    
                    <el-empty description="未查询到相关数据" image="http://www.kjwc.xyz/nodata.png"></el-empty>                  
                </div>
                <div class="col-sm-6 col-lg-4" 
                v-for="item in this.ModsSelect.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)" 
                                :key="item.id">
                    
                    <!--== Start Blog Post Item ==-->
                    <div class="post-item">
                    <div class="thumb">
                        <router-link :to="{path:'/ModList',query: {id:item.id}}">
                            <a href="">
                                <img :src="item.pImage" alt="Image" style="width:100%;height:100%" v-if="item.pImage">
                                <img src="../../assets/img/nopic.png" style="width:100%;height:100%" v-if="!item.pImage">
                            </a>
                        </router-link>
                    </div>
                    <div class="content">
                                        <div class="author">By 
                                        <a href="/ModList" v-if="item.assets==1">Word模板</a>
                                        <a href="/ModList" v-if="item.assets==2">Excel模板</a>
                                        <a href="/ModList" v-if="item.assets==3">PPT模板</a>
                                        <a href="/ModList" v-if="item.assets==4">广告设计素材专区</a>
                                        <a href="/ModList" v-if="item.assets==5">资源分享区</a>
                                        </div>
                                        <h4 class="title"><router-link :to="{path:'/ModList',query: {id:item.id}}"><a href="">{{item.name}}</a></router-link></h4>
                                        <p>{{item.eintro}}</p>
                                        <div class="meta">
                                            <span class="post-date">{{item.upload.slice(0,10)}}</span>
                                            <span class="dots"></span>
                                            <template v-if="item.upload" >
                                            <span class="post-time" >{{dateTimes}}</span>
                                            </template>
                                        </div>
                                    </div>
                    </div>
                    <!--== End Blog Post Item ==-->
                </div>
                <el-pagination
                                @current-change="handleCurrentChange"
                                background
                                layout="prev, pager, next, jumper"
                                :current-page="currentPage"
                                :page-size="pageSize"
                                :total="ModsSelect.length" style="text-align: center;">
                            </el-pagination>
                </div>
                
            </div>
            </section>
            <!--== End Blog Area Wrapper ==-->
        </main>
    </div>
</template>
<script>
import {selectByNameOrLaber} from '../../api/index'
export default {
    data(){
        return{
            newkey:'',//新的专区名称
            key:'',//专区名称
            titlePic:'',//专区logo图片
            titleName:'',//专区名称
            ModsLength:'',//专区内容跳数
            ModsSelect:[],//专区数据
            pageSize: 6, // 一页显示多少条数据
            currentPage: 1, // 当前页
            dateTimes:'三天前'
        }
    },
    created(){
        this.key=this.$route.query.name     
        this.getOfLabels()
       
    },
    watch: {			
       
        
    },
    methods:{
         // 获取当前页
        handleCurrentChange (val) {
        this.currentPage = val
        },
        goModHome(){
            this.$router.push({path: `/ModsView`})
        },
        async getOfLabels(){
            this.ModsSelect = []
            await selectByNameOrLaber(this.key).then(res => {
                this.ModsSelect=res,
                this.currentPage = 1,
                this.ModsLength=this.ModsSelect.length
            })                       
        },
    }
}
</script>
<style lang="less" scoped>
    /deep/.el-pagination.is-background .el-pager li:not(.disabled) {
       color: #999;
      border-radius: 5px;
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
 /deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
      background-color: #03A84E;
      color: #fff;//修改选中后的样式
    }
//上一页和下一页的样式
    /deep/.el-pagination.is-background .btn-next{
      //margin: 0 5px;
      background-color: #03A84E;
      color: #fff;
      min-width: 60px;
      height: 60px;
      border-radius: 5px;
    }
    /deep/.el-pagination.is-background .btn-prev{
      //margin: 0 5px;
     background-color: #03A84E;
      color: #fff;
      min-width: 60px;
      height: 60px;
      border-radius: 5px;
    }
   /deep/.el-pagination.is-background .el-pagination__jump{

      height: 60px;
      line-height: 60px;
   }
</style>